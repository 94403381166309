import React from 'react';

function Footer() {
  return (
    <footer>
      <p>Made it with purple love 💜 <a href="https://snort.social/p/npub1gzuushllat7pet0ccv9yuhygvc8ldeyhrgxuwg744dn5khnpk3gs3ea5ds" target="_blank" rel="noreferrer">Gzuuus</a> · 
      <a href="https://github.com/gzuuus/nostref" target="_blank" rel="noreferrer"> Repo</a> · 
      <a href="lightning:gzuuus@getalby.com" rel="noreferrer"> ⚡ Support</a></p>
    </footer>
  );
}

export default Footer;
